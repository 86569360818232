import React from 'react'
import Product from './Product'

const Products = () => {
  return (
    <div>
        <Product/>
    </div>
  )
}

export default Products